import http from "../../utils/http"
/**
 * รับข้อมูลพื้นฐานเกี่ยวกับคะแนน
 */
export function pointInfo(params) {
    return http({
        url: "/api/memberaccount/info",
        data: params,
        forceLogin: true
    })
}
/**
 * รับรายการคะแนน
 */
export function pointList(params) {
    return http({
        url: "/api/memberaccount/page",
        data: params,
        forceLogin: true
    })
}
